// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgBNBj-jOlHOGDxitHupnflQAHvZzNOR4",
  authDomain: "happyours-6ba79.firebaseapp.com",
  projectId: "happyours-6ba79",
  storageBucket: "happyours-6ba79.appspot.com",
  messagingSenderId: "896394905193",
  appId: "1:896394905193:web:1ce978ae9a8d70ace6c4f8",
  measurementId: "G-38YE81DPYL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export {app};
export default analytics;
