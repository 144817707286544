import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Happy Ours
        </p>
        <p className="App-link">
          Love Knows No Labels: Find Your Forever Here
        </p>
      </header>
    </div>
  );
}

export default App;
